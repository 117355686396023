import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import {auth1} from "../firebase";
import router from '../router/index'
// import firebase from 'firebase'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        userId:null,
        userProfile: {},
        allInterpretersProfiles: {},
        allClerksProfiles: {},
        allJudgesProfiles: {},
        allKiosksProfiles: {},
        signingUpUser: false,
        videoRoomName:'',
        callStatus:{},
    },
    mutations: {
        setUserId(state, userId){
            state.userId = userId;
        },
        setUserProfile(state, profileData) {
            state.userProfile = profileData;
        },
        setAllInterpretersProfiles(state, users){
            state.allInterpretersProfiles = users;
        },
        setAllClerksProfiles(state, users){
            state.allClerksProfiles = users;
        },
        setAllJudgesProfiles(state, users){
            state.allJudgesProfiles = users;
        },
        setSignUpUser(state, status){
            state.signingUpUser = status;
        },
        setAllKiosksProfiles(state, kiosks){
            state.allKiosksProfiles = kiosks;
        },
        setVideoRoomName(state, roomName){
            state.videoRoomName = roomName;
        },
        setCallStatus(state, status){
            state.callStatus = status;
        }
    },
    getters:{

    },
    actions: {
        login({ commit, dispatch }, form) {
            return new Promise((resolve, reject) => {
                // sign user in
                fb.auth.signInWithEmailAndPassword(form.email, form.password).then((cred) =>{
                    console.log('user Logged In: ', cred.user.uid);
                    // set user id in state
                    commit('setUserId', cred.user.uid);
                    // fetch user profile and set in state
                    dispatch('fetchUserProfile', cred.user);
                    //set user status to online
                    dispatch('setUserOnline');
                    resolve('success');
                })
                    .catch((error)=>{
                        console.log(error.message);
                        reject('Invalid Email or Password. Please try again');
                    })

            })

        },
        logOut({dispatch, commit}){
            // set user offline
            dispatch('setUserOffline');
              fb.auth.signOut().then(() => {
                  //reset user data
                  console.log('User has signed out');
                  commit('setUserProfile', {});
                  commit('setUserId', null);
                  // route to login page
                  // router.push('/login');
                  setTimeout(()=>{location.reload()},500 );
              })
        },
        fetchUserProfile({dispatch, commit, state }, user) {
            if(!state.signingUpUser){
                // fetch user profile
                fb.usersCollection.doc(user.uid).onSnapshot((userProfile) => {
                    // set user id in state
                    commit('setUserId', user.uid);
                    // set user profile in state
                    commit('setUserProfile', userProfile);
                    //fetch all profiles
                    dispatch('fetchAllProfiles');
                    //fetch all kiosks
                    dispatch('fetchAllKiosks');
                    // change route to dashboard
                    if (router.currentRoute.path === '/login') {
                        router.push('/')
                    }
                });
            }

        },
        fetchAllProfiles({state, commit}){
            //get the local username
            const userName = state.userProfile.data().name;
            //fetch all Interpreters profiles
            fb.usersCollection.where('userRole', '==', 'Interpreter').where('name','!=',userName).onSnapshot((snapshot) => {
                // set all Interpreters profiles in state
                commit('setAllInterpretersProfiles', snapshot.docs);
            });
            //fetch all clerks profiles
            fb.usersCollection.where('userRole', '==', 'Clerk').where('name','!=',userName).onSnapshot((snapshot) => {
                // set all clerks profiles in state
                commit('setAllClerksProfiles', snapshot.docs);
            });
            //fetch all judges profiles
            fb.usersCollection.where('userRole', '==', 'Judge').where('name','!=',userName).onSnapshot((snapshot) => {
                // set all judges profiles in state
                commit('setAllJudgesProfiles', snapshot.docs);
            });
        },
        fetchAllKiosks({commit}){
            //fetch all kiosks
            fb.kiosksCollections.onSnapshot((snapshot) => {
                //set all kiosks profiles in state
                commit('setAllKiosksProfiles', snapshot.docs);
            });
        },
        setUserOnline({state}){
            const userRef = fb.usersCollection.doc(state.userId);
            userRef.update({
                onlineStatus: 'green'
            })
        },
        setUserOffline({state}){
            const userRef = fb.usersCollection.doc(state.userId);
            userRef.update({
                onlineStatus: 'red'
            })
        },
        setUserBusy({state}){
            const userRef = fb.usersCollection.doc(state.userId);
            userRef.update({
                onlineStatus: 'yellow'
            })
        },
        registerNewUser({commit},newUser){
            return new Promise((resolve, reject) => {
                commit('setSignUpUser', true);
                auth1.createUserWithEmailAndPassword(newUser.email, newUser.password)
                    .then((cred) => {
                        fb.usersCollection.doc(cred.user.uid).set({
                            name: newUser.name,
                            userRole: newUser.userRole,
                            onlineStatus: 'red',
                            callObj: '{"incomingCall":false,"callerId":"","callerName":"","videoRoomName":""}',
                        }).then(() => {
                            console.log('UserCreated');
                            auth1.signOut();
                            commit('setSignUpUser', false);
                            resolve('User Created Successfully!')
                        })
                            .catch((error) => {
                                reject(error);
                                console.log(error.message);
                            })
                    })
                    .catch((error) => {
                        console.log(error.message)
                    });
            })
        },
        updateProfile({state}, userObj){
            return new Promise((resolve, reject) => {
                const userRef = fb.usersCollection.doc(state.userId);
                userRef.update({
                    name: userObj.name,
                    userRole: userObj.userRole,
                }).then(()=>{
                    resolve('Profile updated successfully');
                }).catch((error) =>{
                    reject(error);
                })
            });
        },
        sendPasswordResetEmail(){
            return new Promise((resolve, reject)=>{
                const userEmail = fb.auth.currentUser.email;

                fb.auth.sendPasswordResetEmail(userEmail)
                    .then(()=>{
                        resolve("Password update link sent to: "+ userEmail);
                    })
                    .catch((error)=>{
                        reject(error)
                    });
            });
        },
        sendForgotPasswordResetEmail({state},email){
            console.log(state.userId);
            return new Promise((resolve, reject)=>{
                fb.auth.sendPasswordResetEmail(email)
                    .then(()=>{
                        resolve("Please check your Email (" +email+ ") for Password Reset Link");
                    })
                    .catch((error)=>{
                        reject("Invalid Email, make sure you are a registered User. ERROR("+error+")")
                    });
            });
        },
        addNotification({state}, notification){
            return new Promise((resolve, reject)=>{
                const userRef = fb.usersCollection.doc(state.userId);
                userRef.update({
                    notifications: fb.firebase.firestore.FieldValue.arrayUnion(notification),
                }).then(()=>{
                    resolve('Notification added successfully');
                }).catch((error) =>{
                    reject(error);
                })
            })
        },
        clearNotifications({state}){
          return new Promise((resolve, reject)=>{
              const userRef = fb.usersCollection.doc(state.userId);
              userRef.update({
                  notifications: '',
              }).then(()=>{
                  resolve('Notification added successfully');
              }).catch((error) =>{
                  reject(error);
              })
          })
        },
        handleIncomingCall({state, commit}, response){
            if(state.userProfile.data().onlineStatus === 'green'){
                if(response === 'accept'){
                    const callObj = JSON.parse(state.userProfile.data().callObj);
                    console.log("CALL OBJ:", callObj);
                    //set video room name locally
                    commit('setVideoRoomName', callObj.videoRoomName);
                    // add user to the videoConference
                    fb.callsConnections.doc(callObj.videoRoomName).update({
                        callStatus: 'accepted',
                        conferenceUsers: fb.firebase.firestore.FieldValue.arrayUnion(state.userId),
                    }).then(() => {
                        //create callObject
                        const callObj = {
                            incomingCall: '',
                            callerId: '',
                            callerName: '',
                            videoRoomName: ''
                        };
                        //update callObject in callee's document
                        fb.usersCollection.doc(state.userId).update({
                            callObj: JSON.stringify(callObj)
                        }).then(() =>{
                            console.log("call Accepted");

                        }).catch(error =>{
                            console.log(error.message);
                        })
                    })
                }// end if-response-accept

                if(response === 'decline'){
                    const callObj = JSON.parse(state.userProfile.data().callObj);
                    //set video room name locally
                    commit('setVideoRoomName', '');
                    // add user to the videoConference
                    fb.callsConnections.doc(callObj.videoRoomName).update({
                        callStatus: 'declined',
                    }).then(() => {
                        //create callObject
                        const callObj = {
                            incomingCall: false,
                            callerId: '',
                            callerName: '',
                            videoRoomName: ''
                        };
                        //update callObject in callee's document
                        fb.usersCollection.doc(state.userId).update({
                            callObj: JSON.stringify(callObj)
                        }).then(() =>{
                            console.log("call Declined")
                        }).catch(error =>{
                            console.log(error.message);
                        })
                    })
                }// end if-response-accept

            }
        },
        handleOutgoingCall({state, commit}, callData){
            console.log("HandleOutGoingCall: CALL OBJ:", callData);
            if(callData.callType === 'new'){
                //create a document in call collection and create roomid as callRef
                const videoRoomRef = fb.callsConnections.doc();
                console.log("HandleOutGoingCall: Video Room Name:", videoRoomRef.id);
                //set video room name locally
                commit('setVideoRoomName', videoRoomRef.id);
                videoRoomRef.set({
                    callStatus: 'initiated',
                    conferenceUsers: [state.userId],
                }).then(() =>{
                    commit('setCallStatus', {callstatus: 'initiated',
                                            calleeId: callData.calleeObj.id,
                                            calleeName: callData.calleeObj.data().name});
                    console.log("HandleOutGoingCall: CALL STATUS:", state.callStatus.callStatus);
                    //create callObject
                    const callObj = {
                        incomingCall: true,
                        callerId: state.userId,
                        callerName: state.userProfile.data().name,
                        videoRoomName: state.videoRoomName,
                    };
                    //update callObject in callee's document
                    fb.usersCollection.doc(callData.calleeObj.id).update({
                        callObj: JSON.stringify(callObj)
                    }).then(() =>{
                        const callRef =  fb.callsConnections.doc(state.videoRoomName).onSnapshot(doc => {
                            if(doc.data().callStatus !== 'initiated'){
                                commit('setCallStatus', {callstatus: doc.data().callStatus,
                                    calleeId: callData.calleeObj.id,
                                    calleeName: callData.calleeObj.data().name});
                                console.log('Unsubscribe the listener');
                                callRef();
                            }
                        })
                    }).catch(error =>{
                        console.log(error.message);
                    })
                })
            }
            if(callData.callType === 'add'){
                fb.callsConnections.doc(state.videoRoomName).update({
                    callStatus: 'addNewUser',
                }).then(()=>{
                    commit('setCallStatus', {callstatus: 'addNewUser',
                        calleeId: callData.calleeObj.id,
                        calleeName: callData.calleeObj.data().name});
                    console.log("HandleOutGoingCall: CALL STATUS:", state.callStatus.callStatus);
                    //create callObject
                    const callObj = {
                        incomingCall: true,
                        callerId: state.userId,
                        callerName: state.userProfile.data().name,
                        videoRoomName: state.videoRoomName,
                    };
                    //update callObject in callee's document
                    fb.usersCollection.doc(callData.calleeObj.id).update({
                        callObj: JSON.stringify(callObj)
                    }).then(() =>{
                        const callRef =  fb.callsConnections.doc(state.videoRoomName).onSnapshot(doc => {
                            if(doc.data().callStatus !== 'addNewUser'){
                                commit('setCallStatus', {callstatus: doc.data().callStatus,
                                    calleeId: callData.calleeObj.id,
                                    calleeName: callData.calleeObj.data().name});
                                console.log('Unsubscribe the listener');
                                callRef();
                            }
                        })
                    }).catch(error =>{
                        console.log(error.message);
                    })

                })

            }

        },
        handleEndCall({state, commit}, calleeId){
            fb.callsConnections.doc(state.videoRoomName).update({
                callStatus: 'cancel',
            }).then(() => {
                //create callObject
                const callObj = {
                    incomingCall: false,
                    callerId: '',
                    callerName: '',
                    videoRoomName: ''
                };
                //update callObject in callee's document
                fb.usersCollection.doc(calleeId).update({
                    callObj: JSON.stringify(callObj)
                }).then(() =>{
                    console.log("call Canceled");
                    //reset video room name
                    commit('setVideoRoomName', '');
                }).catch(error =>{
                    console.log(error.message);
                })
            })
        },

    },

})

export default store