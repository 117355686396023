<template>
    <v-container fluid>
        <v-card flat >
            <v-card-title class="grey--text text--darken-2">My Profile</v-card-title>

            <v-card-text class="px-16">
                <v-form :readonly="readonly" @submit.prevent>
                    <v-text-field v-model="userObj.name"
                                  name="userName" label="Full Name"
                                  type="text"></v-text-field>
                    <v-select :items="userRoles"
                            label="User Role" disabled
                            v-model="userObj.userRole"></v-select>
                    <v-btn  v-show="readonly" elevation="0"
                           @click="readonly = !readonly"
                           class="px-6" color="primary">
                        <v-icon left class="pr-2">mdi-pencil</v-icon>
                        Edit</v-btn>
                    <v-btn  v-show="!readonly" elevation="0" @click="updateProfile"
                           class="px-6" color="primary">
                        <v-icon left class="pr-2">mdi-update</v-icon>
                        Update</v-btn>
                    <v-btn  @click="readonly = !readonly"
                            v-show="!readonly" elevation="0"
                            class="px-6 ml-3" color="error">
                        <v-icon left class="pr-2">mdi-close</v-icon>
                        Cancel</v-btn>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="px-16 py-5">
                <v-btn  class="px-6" elevation="0"
                        @click="updatePassword"
                        color="primary">
                    <v-icon left class="pr-2">mdi-lock-reset</v-icon>
                    Update Password</v-btn>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackBar" :color="snackType">
            <span class="font-weight-medium text-body-1 " >{{snackMessage}}</span>
        </v-snackbar>

    </v-container>
</template>

<script>
    import { mapState } from 'vuex'
    export default{
        name:'profile',
        data(){
            return{
                userRoles: ['Clerk', 'Interpreter', 'Judge' ],
                readonly:true,
                userObj:{
                    name:'', userRole:'',
                },
                snackBar: false,
                snackMessage: '',
                snackType:'',

            }
        },
        methods:{
            updateProfile(){
                this.$store.dispatch('updateProfile', this.userObj)
                    .then((response)=>{
                        console.log(response);
                        this.snackMessage = response;
                        this.snackType = 'success';
                        this.snackBar=true;
                        this.readonly = true;
                    }, error =>{
                        this.snackMessage = error;
                        this.snackType = 'error';
                        this.snackBar=true;
                        console.log(error);
                    })
            },
            updatePassword(){
                this.$store.dispatch('sendPasswordResetEmail')
                    .then((response)=>{
                        this.snackMessage = response;
                        this.snackType = 'success';
                        this.snackBar=true;
                    }, error =>{
                        this.snackMessage = error;
                        this.snackType = 'error';
                        this.snackBar=true;
                    })
            }

        },
        computed:{
            ...mapState(['userProfile']),
        },
        mounted(){
            let userProfile = this.userProfile.data();
            console.log(this.userProfile.data());
            this.userObj.name = userProfile.name;
            this.userObj.userRole = userProfile.userRole;
        }
    }

</script>

<style>

</style>