<template>
  <div class="login">
      <v-app-bar
              app
              prominent
              flat>
          <v-img class="ml-2 my-auto"
                 src="@/assets/logoTemp.png"
                 max-width="250"
                 contain>
          </v-img>
          <v-toolbar-title class="subtitle-1 grey--text">(Beta)</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
              <div class="my-auto" >
                  <v-btn elevation="0"
                         class="mx-1"
                         rounded
                         large
                         outlined
                         min-width="150"
                         @click="signupDialog = !signupDialog"
                         color="primary">
                      Sign Up
                  </v-btn>
                  <v-btn  elevation="0"
                          class="mx-1 grey--text"
                          rounded
                          large
                          min-width="150">
                      <v-icon left>mdi-lifebuoy</v-icon>
                      Help
                  </v-btn>
              </div>
          </v-toolbar-items>
      </v-app-bar>

      <v-container fluid class="px-0 pr-1 py-5 fill-height">
          <v-row no-gutters>
              <v-col sm="6" md="8" lg="8" >
                  <v-img class="float-left flip" src="@/assets/landingPage.png" ></v-img>
              </v-col>
              <v-col sm="6" md="4" lg="4" align-self="center" class="pr-10 ">
                  <v-card flat color="#f5f5f5">
                      <v-card-title class="headline primary--text" >Login</v-card-title>
                      <v-card-text>
                          <v-form @submit.prevent>
                              <v-text-field v-model.trim="loginForm.email"
                                            name="login" label="Email"
                                            type="text"></v-text-field>
                              <v-text-field v-model.trim="loginForm.password"
                                            name="password" label="Password"
                                            type="password" @keyup.enter="login()"></v-text-field>
                              <a @click="forgotPasswordDialog = !forgotPasswordDialog">Forgot Password?</a>
                              <v-btn large class="px-10 float-right" color="primary" @click="login()">Login</v-btn>
                          </v-form>
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
          <v-dialog v-model="signupDialog" max-width="700">
              <v-card class="d-flex flex-column   align-center ">
                  <v-card-title class="headline primary--text">Signup New User</v-card-title>
                  <v-card-text>
                      <v-form @submit.prevent>
                          <v-text-field v-model="signupForm.name"
                                        name="userName" label="Full Name"
                                        :rules="[rules.required]"
                                        type="text"></v-text-field>
                          <v-text-field v-model.trim="signupForm.email"
                                        name="email" label="Email Address"
                                        :rules="[rules.email]"
                                        type="text"></v-text-field>
                          <v-text-field v-model.trim="signupForm.password"
                                        name="password" label="Password"
                                        maxlength="20" counter
                                        :rules="[rules.required, rules.counter]"
                                        type="text"></v-text-field>
                          <v-select
                                  :items="userRoles" :rules="[rules.required]"
                                  label="Select User Role"
                                  v-model="signupForm.userRole"></v-select>
                          <v-text-field v-model.trim="signupForm.passwordTemp"
                                        name="passwordTemp" label="Admin password to create account"
                                        maxlength="20" counter
                                        :rules="[rules.required, rules.counter]"
                                        type="password"></v-text-field>
                          <v-btn class="px-10" large color="primary" @click="registerUser()">Signup</v-btn>
                      </v-form>

                  </v-card-text>
              </v-card>
          </v-dialog>

          <v-dialog v-model="forgotPasswordDialog" max-width="400">
              <v-card class="">
                  <v-card-title class="headline primary--text justify-center">Forgot Password</v-card-title>
                  <v-card-text>
                      <v-form @submit.prevent>
                          <v-text-field v-model.trim="forgotUserEmail"
                                        name="email" label="Registered Email Address"
                                        :rules="[rules.email]"
                                        type="text"></v-text-field>
                          <v-btn class="px-10" block  color="primary" @click="sendPasswordEmail">Reset Password</v-btn>
                      </v-form>
                  </v-card-text>
                  <v-card-text><span>Enter your registered email address to receive password reset link.</span></v-card-text>

              </v-card>
          </v-dialog>
      </v-container>
      <v-footer >
          <v-col class="text-center grey--text" cols="12">
              {{ new Date().getFullYear() }} — <strong>Clara Connect </strong>
              <span class="text-caption">(Beta)</span>
          </v-col>
      </v-footer>

      <v-snackbar v-model="snackbar" :color="snackType">
          <span class="font-weight-medium text-body-1 " >{{snackMessage}}</span>
          <template v-slot:action="{ attrs }">
              <v-btn icon small v-bind="attrs"
                      @click="snackbar = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>

  </div>
</template>

<script>
    //import * as fb from '../firebase'

export default {
  name: 'Login',
    data(){
      return{
          snackbar:false,
          snackMessage:'',
          snackType:'',
          signupDialog: false,
         loginForm:{
             email:'',
             password:''
         },
          signupForm:{
              name: '',
              email: '',
              userRole: '',
              password: '',
              passwordTemp: ''
          },
          forgotUserEmail: '',
          forgotPasswordDialog: false,
          rules: {
            required: value => !!value || 'Required.',
                counter: value => value.length <= 20 || 'Max 20 characters',
                email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
                },
            },
          userRoles: ['Clerk', 'Interpreter', 'Judge' ],
      }
    },
  components: {
  },
    methods:{
        login() {
            this.$store.dispatch('login', this.loginForm)
                .then((response) => {
                    this.snackbar = true;
                    this.snackType = 'error';
                    this.snackMessage = response;
                    console.log("LOGIN():", response)
                }, error => {
                    this.snackbar = true;
                    this.snackType = 'error';
                    this.snackMessage = error;
                    console.log("LOGIN() ERROR:", error);
                })
        },
        registerUser(){
            if(this.signupForm.passwordTemp === 'Backguard1@#'){
                this.$store.dispatch('registerNewUser', this.signupForm)
                    .then((response) =>{
                        this.snackType = 'success';
                        this.snackMessage = response;
                        console.log("REGISTER USER():", response)
                        setTimeout(()=>{this.snackbar = true},50);
                    }, error =>{
                        this.snackType = 'error';
                        this.snackMessage = error;
                        console.log("REGISTER USER() ERROR:", error);
                        this.snackbar = true;
                    })
            }
            else{
                this.snackbar = true;
                this.snackType = 'error';
                this.snackMessage = "Incorrect Administrator Password."
            }
        },
        sendPasswordEmail(){
            this.$store.dispatch('sendForgotPasswordResetEmail', this.forgotUserEmail)
                .then((response) =>{
                    this.snackType = 'success';
                    this.snackMessage = response;
                    this.snackbar = true;
                }, error =>{
                    this.snackType = 'error';
                    this.snackMessage = error;
                    this.snackbar = true;
                })
        }

    }
}
</script>

<style>
    .flip{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
</style>
