<template>
  <v-app style="background-color: #f5f5f5 !important;">
      <navBar v-if="showNav"></navBar>
      <v-main>
          <router-view></router-view>
      </v-main>
  </v-app>
</template>


<script>
    import navBar from '@/components/navigation/navBar.vue';
    import { mapState } from 'vuex'


    export default {
      name: 'App',

      components: {
          navBar
      },

      data: () => ({
        //
      }),
      computed:{
          ...mapState(['userProfile']),
          showNav() {
              return Object.keys(this.userProfile).length > 1
          }
      },

    };
</script>


