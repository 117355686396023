<template>
    <div>
        <v-app-bar app color="primary" dark short clipped-left flat>
            <v-img class=" my-auto"
                   src="@/assets/logoTemp.png"
                   max-width="180"
                   contain>
            </v-img>
            <v-toolbar-title class="text-caption" dark>(Beta)</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-menu open-on-hover offset-y :close-on-content-click="false" bottom left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on">
                            <span class=" text-capitalize mr-2 " >Hello, {{userProfile.data().name}} </span>
                            <v-badge bordered :color="userProfile.data().onlineStatus" dot
                                    offset-x="13" offset-y="38">
                                <v-avatar  size="45">
                                    <v-icon x-large dark>mdi-account-circle</v-icon>
                                </v-avatar>
                            </v-badge>
                        </v-btn>
                    </template>
                    <v-list >
                        <v-list-group v-model="item"  no-action >
                            <template v-slot:activator>
                                <v-list-item>
                                    <v-icon dense class="mr-4"
                                            :color="userProfile.data().onlineStatus" >
                                        mdi-checkbox-blank-circle</v-icon>
                                    <v-list-item-title v-text="onlineStatus[userProfile.data().onlineStatus]" ></v-list-item-title>
                                </v-list-item>
                            </template>

                            <v-list-item v-for="(status,i) in statuses" :key="i" class="pa-0">
                                <v-btn text block @click="statusChange(status)" class="font-weight-regular text-capitalize">
                                    <v-col align="end">{{onlineStatus[status]}}</v-col>
                                    <v-col align="start" cols="4"><v-icon right small :color="status">
                                        mdi-checkbox-blank-circle
                                    </v-icon></v-col>
                                </v-btn>
                            </v-list-item>
                        </v-list-group>
                        <v-divider></v-divider>
                        <v-btn class="text-capitalize font-weight-regular"
                               text block to="/profile" >
                            My Profile</v-btn>
                        <!--<v-btn class="text-capitalize font-weight-regular" text block>-->
                            <!--Settings</v-btn>-->
                        <v-btn class="text-capitalize font-weight-regular"
                               text block @click="$store.dispatch('logOut')" >
                            Sign-Out</v-btn>
                    </v-list>
                </v-menu>

                <notificationPanel></notificationPanel>

            </v-toolbar-items>
        </v-app-bar>

        <navDrawer ></navDrawer>

    </div>

</template>

<script>
    import navDrawer from '@/components/navigation/navDrawer.vue';
    import notificationPanel from '@/components/navigation/notificationPanel.vue';
    import { mapState } from 'vuex'

    export default {
        name: 'navBar',
        components:{
            navDrawer,
            notificationPanel,

        },
        data(){
          return{

              onlineStatus: {green: 'Available', yellow: 'Busy', red: 'Unavailable'},
              statuses: ['green', 'yellow', 'red'],
              item: false,

          }
        },
        methods:{
            statusChange(status){
                switch(status){
                    case 'green':
                        this.$store.dispatch('setUserOnline');
                        break;
                    case 'yellow':
                        this.$store.dispatch('setUserBusy');
                        break;
                    case 'red':
                        this.$store.dispatch('setUserOffline');
                        break;
                    default:
                        break;
                }
            }


        },
        computed:{
            ...mapState(['userProfile']),
        },
    }

</script>

<style>

</style>