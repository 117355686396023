<template>
    <v-navigation-drawer app clipped :width="drawerWidth" color="white" >
        <v-row class="fill-height" no-gutters>
            <v-navigation-drawer
                    color="primary"
                    dark
                    mini-variant
                    mini-variant-width="60"
                    permanent>

                <v-list nav>
                    <v-list-item-group v-model="selectedItem">
                        <v-list-item v-for="(item,i) in itemsTop" :key="i" class="d-flex">
                            <v-tooltip right color="black">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item-content v-bind="attrs" v-on="on" class="justify-center">
                                        <v-icon >{{ item.icon }}</v-icon>
                                        <span class="d-inline-block text-truncate" style="font-size: 10px !important;">
                                            {{item.title}}
                                        </span>
                                    </v-list-item-content>
                                </template>
                                <span>{{item.title}}</span>
                            </v-tooltip>
                        </v-list-item>
                    </v-list-item-group>
                    <v-divider ></v-divider>
                </v-list>
                <template v-slot:append>
                    <v-list dense nav>
                        <v-list-item-group>
                            <v-list-item v-for="(item,i) in itemsBottom" :key="i">
                                <v-tooltip right color="black">
                                    <template v-slot:activator="{on, attrs}">
                                        <v-list-item-content v-bind="attrs" v-on="on"
                                                             @click="logOut">
                                            <v-icon>{{item.icon}}</v-icon>
                                            <span class="d-inline-block text-truncate" style="font-size: 10px !important;">
                                            {{item.title}}
                                        </span>

                                        </v-list-item-content>
                                    </template>
                                    <span>{{item.title}}</span>
                                </v-tooltip>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>
            </v-navigation-drawer>

            <v-col>
                <v-list v-show="selectedItem === 0" class="grow">
                    <v-list-item
                            v-for="(item,i) in itemsTop"
                            :key="i"
                            link @click="mainMenuList(item.selection)">
                        <v-list-item-title v-text="item.title" ></v-list-item-title>
                    </v-list-item>
                </v-list>

                <clerkNav v-show="selectedItem === 1"></clerkNav>
                <judgeNav v-show="selectedItem === 2"></judgeNav>
                <interpreterNav v-show="selectedItem === 3" ></interpreterNav>

            </v-col>
        </v-row>
        <incomingCallDialog></incomingCallDialog>
        <outgoingCallDialog></outgoingCallDialog>
    </v-navigation-drawer>
</template>

<script>

    import clerkNav from '@/components/navigation/clerkNav.vue';
    import judgeNav from '@/components/navigation/judgeNav.vue';
    import interpreterNav from '@/components/navigation/interpreterNav.vue';
    import incomingCallDialog from '@/components/incomingCallDialog.vue';
    import outgoingCallDialog from '@/components/outgoingCallDialog.vue';

    export default {
        name: 'navDrawer',
        components:{
            clerkNav,
            judgeNav,
            interpreterNav,
            incomingCallDialog,
            outgoingCallDialog
        },
        data() {
            return {
                selectedItem:0,
                itemsTop: [
                    {title: 'Home', icon: 'mdi-view-dashboard', selection: 0},
                    {title: 'Clerks', icon: 'mdi-account-star', selection: 1},
                    {title: 'Judges', icon: 'mdi-gavel', selection:2},
                    {title: 'Interpreters', icon: 'mdi-translate', selection: 3},
                ],
                itemsBottom:[
                    // {title: 'Settings', icon:'mdi-cog', action:''},
                    // {title: 'Help', icon: 'mdi-lifebuoy', action:''},
                    {title: 'Sign-Out', icon:'mdi-logout-variant', action: 'logOut'},
                ],
                links: ['Home', 'Clerks', 'Judges', 'Interpreters', 'Settings', 'Help', 'Sign-Out'],

            }
        },
        methods:{
            logOut(){
                this.$store.dispatch('logOut');
            },
            mainMenuList(item){
                this.selectedItem = item;
                if(item === 0 && this.$router.currentRoute.path !== '/'){
                    this.$router.push('/')
                }
            }
        },
        computed:{
            drawerWidth(){
                if(!this.selectedItem && this.selectedItem !== 0){
                    return 64;
                }
                else{
                    return 300;
                }
            }
        }
    }

</script>

<style>

</style>