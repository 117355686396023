<template>
    <v-menu open-on-hover offset-y max-width="400" max-height="600"
            :close-on-content-click="false" bottom left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon text v-bind="attrs" v-on="on">
                <v-badge bordered  dot color="red"
                         overlap :value="notificationList.length !== 0" >
                    <v-icon >mdi-bell</v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-list two-line class="">
            <v-list-item v-for="(notification,i) in notificationList" :key="i" >
                <v-icon color="red" left class="pr-2">mdi-phone-missed</v-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="notification.title"></v-list-item-title>
                    <v-list-item-subtitle v-text="notification.time"></v-list-item-subtitle>
                    <v-divider></v-divider>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-show="notificationList.length === 0">
                <v-list-item-content>
                    <span class="grey--text darken-4">No new notifications.</span>
                </v-list-item-content>
            </v-list-item>

        </v-list>
        <v-btn v-show="notificationList.length !== 0"
               tile color="primary"
               @click="$store.dispatch('clearNotifications')"
               elevation="0" block small >
            clear all
        </v-btn>
    </v-menu>

</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'notificationPanel',
        data(){
            return{
                show: false,
            }
        },
        computed:{
            ...mapState(['userProfile']),
            notificationList(){
                let list = this.userProfile;
                list = list.data().notifications;
                list = Array.from(list)
                list.reverse()

                return list;
            }
        },
    }
</script>

<style>

</style>