<template>
    <v-dialog max-width="500px" max-height="300px" v-model="dialog"  :persistent="persistent" >
        <v-card class="pa-3" height="230px" rounded >
            <div v-if="callState === 'initiated'">
                <p class="text-center font-weight-medium "> Calling... </p>
                <p class="text-center headline font-weight-light"> {{calleeName}} </p>
                <v-row >
                    <v-col cols="12" class="text-center">
                        <v-btn class="mx-2" fab dark large color="red" @click="endCall" >
                            <v-icon dark>mdi-phone-hangup</v-icon>
                        </v-btn>

                    </v-col>
                </v-row>
                <div class="pt-3">
                    <v-progress-linear
                            indeterminate
                            stream
                            reverse
                            background-opacity=0.2
                            color="primary"
                    ></v-progress-linear>
                    <v-progress-linear
                            indeterminate
                            stream
                            background-opacity=0.2
                            color="primary"
                    ></v-progress-linear>
                </div>
            </div>

            <div v-if="callState === 'declined'">
                <div class="d-flex justify-end">
                    <v-btn class="mx-2 float-right" dark fab small color="red" @click="closeDialog"  >
                        <v-icon dark>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-actions class="d-flex flex-column">
                    <p class="text-center headline font-weight-light"> {{calleeName}} </p>
                    <p class="text-center font-weight-medium "> Did not answer the call </p>
                </v-card-actions>
            </div>


        </v-card>

    </v-dialog>

</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: 'outgoingCallDialog',

        data(){
            return{
                dialog: false,
                callState:null,
                persistent: true,
                dialogTimer: null,
                calleeId:null,
                calleeName: null

            }
        },
        methods:{
            endCall(){
                clearTimeout(this.dialogTimer);
                console.log("OUTGOING CALL:", this.calleeId);
                this.$store.dispatch('handleEndCall', this.calleeId);
                this.$store.dispatch('setUserOnline');
                this.closeDialog();
            },
            startCall(){
                clearTimeout(this.dialogTimer);
                this.$store.dispatch('handleOutgoingCall', this.calleeId);
                this.closeDialog();
            },
            closeDialog(){
                clearTimeout(this.dialogTimer);
                this.dialog=false;
            }

        },
        computed:{
            ...mapState(['callStatus']),
        },
        watch:{
            callStatus: function (newVal) {
                this.callState = newVal.callstatus;
                this.calleeId = newVal.calleeId;
                this.calleeName = newVal.calleeName;
                console.log(newVal);


                if(this.callState === 'accepted'){
                    console.log('Call Accepted');
                    if(this.$router.currentRoute.path !== '/conference'){
                        this.$router.push('/conference');
                    }
                    this.closeDialog();
                }
                if(this.callState === 'initiated'){
                    this.dialog = true;
                    this.$store.dispatch('setUserBusy');
                    console.log('Call Initiated');
                }
                if(this.callState === 'declined'){
                    this.$store.dispatch('setUserOnline');
                    clearTimeout(this.dialogTimer);
                    this.dialogTimer = setTimeout(()=>{
                        this.closeDialog();
                    }, 5000);
                    console.log('Call Declined');
                }
                if(newVal === 'addNewUser'){
                    console.log("CallState: AddNewUser");
                }
            }
        },
        beforeDestroy(){
            clearTimeout(this.dialogTimer);
        }
    }

</script>

<style>

</style>