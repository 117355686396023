import Vue from 'vue'
import VueRouter from 'vue-router'
import {auth} from '../firebase'
import Login from '../views/Login.vue'
import dashboard from '../views/Dashboard.vue'
import userDashboard from '../components/general/userDashboard.vue'
import profile from '../components/general/profile.vue'
import conferenceRoom from '../components/conferenceRoom.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
      meta:{
          requiresGuest: true
      }
  },
    {
        path: '/',
        name: 'Dashboard',
        component: dashboard,
        meta:{
            requiresAuth: true
        },
        children: [
            {
                path: '/',
                name: 'userDashboard',
                component: userDashboard,
                meta:{
                    requiresAuth: true
                },
            },
            {
                path: '/conference',
                name: 'conferenceRoom',
                component: conferenceRoom,
                meta:{
                    requiresAuth: true
                },

            },
            {
                path: '/profile',
                name: 'profile',
                component: profile,
                meta:{
                    requiresAuth: true
                },

            },

        ]
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

    if (requiresAuth && !auth.currentUser) {
        next('/login')
    } else {
        next()
    }
})

export default router
