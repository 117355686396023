<template>
    <v-dialog max-width="500px" max-height="300px" v-model="dialog" persistent >
        <v-card class="pa-3" height="230px" rounded >
            <div v-if="callState">
                <p class="text-center font-weight-medium "> Receiving A Call From </p>
                <p class="text-center headline font-weight-light"> {{callerName}}</p>

                <v-row >
                    <v-col cols="6" class="text-center " >
                        <v-btn class="mx-2" fab dark large color="green" @click="acceptCall" >
                            <v-icon dark>mdi-phone</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="text-center ">
                        <v-btn class="mx-2" fab dark large color="red" @click="declineCall" >
                            <v-icon dark>mdi-phone-hangup</v-icon>
                        </v-btn>

                    </v-col>
                </v-row>
                <div class="pt-3">
                    <v-progress-linear
                            indeterminate
                            reverse
                            striped
                            background-opacity=0
                            color="red darken-2"
                    ></v-progress-linear>

                    <v-progress-linear
                            indeterminate
                            striped
                            background-opacity=0
                            color="green"
                    ></v-progress-linear>

                    <v-progress-linear
                            indeterminate
                            reverse
                            striped
                            background-opacity=0
                            color="red darken-2"
                    ></v-progress-linear>

                    <v-progress-linear
                            indeterminate
                            striped
                            background-opacity=0
                            color="green"
                    ></v-progress-linear>
                </div>
            </div>

            <div v-if="callState === false">
                <div class="d-flex justify-end">
                    <v-btn class="mx-2 float-right" dark fab small color="red" @click="closeDialog"  >
                        <v-icon dark>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-actions class="d-flex flex-column">
                    <p class="text-center font-weight-medium "> You missed a call from </p>
                    <p class="text-center headline font-weight-light"> {{callerName}} </p>
                </v-card-actions>
            </div>
        </v-card>
        <audio id="myAudio">
        </audio>
    </v-dialog>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'incomingCallDialog',
        props:['caller', ],
        data(){
            return{
                dialog:false,
                callerName:'',
                callState: null,
                dialogTimer:null,
                callAccepted:false,
                userProfileCallObj: this.userProfile,

            }
        },
        computed:{
            ...mapState(['userProfile']),
        },
        watch:{
            userProfile: function (newVal, oldVal) {
                if(newVal.data().callObj !== oldVal.data().callObj)
                {
                    const callObj = JSON.parse(newVal.data().callObj);
                    this.callState = callObj.incomingCall;
                    console.log("Incoming Call: CALL STATE:", this.callState);
                    if(callObj.incomingCall){
                        this.callerName = callObj.callerName;
                        this.dialog = true;
                        this.startRinger();
                        if (Notification.permission === "granted") {
                            const nmjsImg = require('@/assets/nmjs.png');
                            const callText = `Receiving a call from: ${this.callerName}`;
                            const options = {
                                body: "Click to answer",
                                icon: nmjsImg
                            };
                            let notification = new Notification(callText, options);
                            notification.onclick = function () {
                                window.focus();
                            }
                        }
                        else{
                            Notification.requestPermission();
                        }
                        clearTimeout(this.dialogTimer);
                        this.dialogTimer = setTimeout(() => {
                            this.declineCall();
                            this.createNotification();
                        }, 20000);
                    }
                    if(!callObj.incomingCall){
                        this.endRinger();
                        if(this.callAccepted){
                            this.callAccepted = false;
                            this.closeDialog()
                        }
                        else{
                            console.log("CALL ACCEPTED in auto callstate");
                            clearTimeout(this.dialogTimer);
                            this.dialogTimer = setTimeout(()=>{
                                this.closeDialog();
                                this.createNotification();
                            }, 5000);
                        }

                    }

                }

            }
        },
        methods:{
            acceptCall(){
                this.callAccepted = true;
                this.endRinger();
                this.$store.dispatch('setUserBusy');
                this.$store.dispatch('handleIncomingCall', 'accept');
                this.$router.push('/conference');
            },
            declineCall(){
                console.log('INCOMING CALL: CallDecline');
                this.endRinger();
                this.$store.dispatch('handleIncomingCall', 'decline');
                this.closeDialog();
            },
            closeDialog(){
                clearTimeout(this.dialogTimer);
                this.dialog=false
            },
            startRinger(){
                setTimeout(()=>{
                    try {
                        let ringer = document.getElementById('myAudio');
                        ringer.setAttribute('src', require('../assets/ring.mp3'));
                        ringer.setAttribute('type', 'audio/mpeg');
                        ringer.loop = true;
                        ringer.load();
                        ringer.play();
                        //console.log('Current SRC:', ringer.currentSrc);
                    }
                    catch (e) {
                        console.log("Ringer Set attribute error")
                    }

                }, 20);
            },
            endRinger(){
                try {
                    let ringer = document.getElementById('myAudio');
                    ringer.setAttribute('src', require('../assets/silence.mp3'));
                    ringer.load();
                    //console.log('Current SRC:', ringer.currentSrc);
                }
                catch (e) {
                    console.log("Ringer Set attribute error")
                }

            },
            createNotification(){
                const date = new Date();
                const timeStamp =  date.toDateString()+' '+ date.toLocaleTimeString('en-US');
                const notification = {title: `You missed a call from ${this.callerName}`,
                    time: timeStamp};
                this.$store.dispatch('addNotification', notification);
            }
        },
    }

</script>

<style>

</style>