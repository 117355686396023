<template>
    <v-card max-width="280" hover class=" d-flex justify-center pa-2 ma-2 align-center " :class="kioskObj.data().onlineStatus" >
        <v-avatar size="70">
            <img src="./../assets/nmjs.png">
        </v-avatar>
        <v-card-text>
            <div class="text-subtitle-1 black--text">
                {{kioskObj.data().location}}
            </div>
            <div class="grey-text">
                {{kioskObj.data().onlineStatus}}
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'kiosks',
        props:['kioskObj'],
        data(){
            return{
                kiosk: this.kioskObj,
            }
        },
        watch:{
            // kioskObj: function(newVal, oldVal) {
            //     console.log('Prop changed: ', newVal.data(), ' | was: ', oldVal.data());
            //     if(newVal.data().callStatus === 'calling'){
            //         console.log("Receiving A call");
            //     }
            // }
        },
        mounted(){


        }
    }

</script>

<style>
    .online{
        border-left: 8px solid forestgreen !important;
    }
    .offline{
        border-left: 8px solid #E53935 !important;
    }
    .busy{
        border-left: 8px solid #FDD835 !important;
    }

</style>