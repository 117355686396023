import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

//----------------------------------Production-----------------------------------
var firebaseConfig = {
    apiKey: "AIzaSyA2yKbPhu7B8hNv8jf7qNgHyTNfIA-tNGk",
    authDomain: "nmdashboard-9085a.firebaseapp.com",
    databaseURL: "https://nmdashboard-9085a.firebaseio.com",
    projectId: "nmdashboard-9085a",
    storageBucket: "nmdashboard-9085a.appspot.com",
    messagingSenderId: "73569396558",
    appId: "1:73569396558:web:b2f8937417d9644ca8a01f",
    measurementId: "G-Q5VCYWJPMP"
};
//----------------------------------Production-----------------------------------
//-------------------------------------------------------------------------------
//----------------------------------Staging-----------------------------------
// var firebaseConfig = {
//     apiKey: "AIzaSyBUIyXMZ4hq1tXUiCpInxWIkSy1QtHhZCE",
//     authDomain: "nmdashboardstaging.firebaseapp.com",
//     projectId: "nmdashboardstaging",
//     storageBucket: "nmdashboardstaging.appspot.com",
//     messagingSenderId: "665661586010",
//     appId: "1:665661586010:web:d96986adc28988b126b663"
// };
//----------------------------------Staging-----------------------------------

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
const auth1 = firebase.auth();

// collection references
const usersCollection = db.collection('users');
const kiosksCollections = db.collection('kiosks');
const callsConnections = db.collection('calls');

//db.onDisconec

// export utils/refs
export {
    db,
    auth,
    auth1,
    usersCollection,
    kiosksCollections,
    callsConnections,
    firebase,
}


