<template>
    <v-container fluid class="pa-2">
        <router-view></router-view>
    </v-container>
</template>

<script>

    import { mapState } from 'vuex'

    export default {
        name: 'dashboard',
        data: function(){
            return{
            }
        },
        components: {



        },
        methods: {
        },
        created(){

        },
        mounted: function () {

        },
        computed:{
            ...mapState(['userProfile']),
        }
    }
</script>

<style scoped>



</style>
