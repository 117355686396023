<template>
    <v-container fluid class="fill-height">
        <v-row id="video-panel"></v-row>
        <v-row align="center" justify="center">
            <v-btn block color="red darken-4" dark x-large class="title" @click="endCall">
                End Call
                <v-icon right large class="pl-7">mdi-phone-hangup</v-icon>
            </v-btn>
        </v-row>

    </v-container>


</template>

<script>
    import { mapState } from 'vuex'
    import JitsiMeetExternalAPI from './../external_api'

    export default {
        name: 'conferenceRoom',
        data(){
            return{
                api:null,
            }
        },
        mounted(){
            const domain = 'clara-connect1.arsconnect.com';
            const options = {
                roomName: this.videoRoomName,
                width: '100%',
                height: window.innerHeight - 150,
                parentNode: document.querySelector('#video-panel'),
                interfaceConfigOverwrite: {
                    DISPLAY_WELCOME_PAGE_CONTENT: false,
                    CLOSE_PAGE_GUEST_HINT: false,
                    SHOW_JITSI_WATERMARK: false,
                    HIDE_INVITE_MORE_HEADER: true,
                    DEFAULT_BACKGROUND: '#f5f5f5',
                    TOOLBAR_ALWAYS_VISIBLE: true,

                    TOOLBAR_BUTTONS: [
                        'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
                        'fodeviceselection', 'profile', 'etherpad', 'settings', 'raisehand',
                        'videoquality', 'filmstrip', 'invite', 'stats', 'shortcuts',
                        'tileview', 'mute-everyone'
                    ],
                },
                userInfo: {
                    email: '',
                    displayName: this.userProfile.data().name,
                },
            };
            this.api = new JitsiMeetExternalAPI(domain, options);

            this.$store.dispatch('setUserBusy');
        },
        computed:{
            ...mapState(['callStatus', 'userProfile', 'videoRoomName']),

        },
        methods:{
            endCall(){
                this.$router.push({path:'/'});
                this.$store.dispatch('setUserOnline');
            }
        },

    }

</script>

<style>

</style>