<template>
    <v-container fluid  >
        <v-card class="" elevation="0">
            <v-card-title class="grey--text text--darken-3">
                Available Kiosks
            </v-card-title>
            <v-card-actions class="d-flex">
                <kiosk v-for="(kiosk, i) in allKiosksProfiles" :kioskObj="kiosk" :key="i"  ></kiosk>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    import { mapState } from 'vuex';
    import kiosk from '@/components/kiosks.vue';

    export default {
        name: 'userDashboard',
        components:{
            kiosk
        },
        data(){
            return{

            }
        },
        computed:{
            ...mapState(['allKiosksProfiles']),
        }
    }

</script>

<style>

</style>