<template>
    <v-container class="pa-0 " style="overflow: hidden; overflow-x: hidden; overflow-y: hidden " >
        <!--<v-card tile>-->
            <v-row class="d-flex flex-column align-center mx-2 pt-2 mb-3 ">
                <v-row no-gutters class="">
                    <v-text-field
                            dense
                            hide-details
                            v-model="searchText"
                            placeholder="Search Judges">
                    </v-text-field>
                    <v-btn icon
                           @click="ascending = !ascending"
                           color="gray">
                        <v-icon v-show="ascending">mdi-sort-alphabetical-ascending</v-icon>
                        <v-icon v-show="!ascending">mdi-sort-alphabetical-descending</v-icon>
                    </v-btn>
                </v-row>
                <v-radio-group
                        class="pa-0 ma-0 font-weight-light"
                        row
                        v-model="radios"
                        dense
                        hide-details
                        label="Show:"
                        persistent-hint
                        mandatory>
                    <v-radio
                            class="ma-1 font-weight-light"
                            label="Online"
                            value="green"
                    ></v-radio>
                    <v-radio
                            class="ma-1 font-weight-light"
                            label="All"
                            value="red"
                    ></v-radio>
                </v-radio-group>
            </v-row>
            <v-divider></v-divider>
        <!--</v-card>-->
        <v-virtual-scroll v-if="filteredJudges.length > 0"
                          :items="filteredJudges" :height="windowHeight" item-height="45">
            <template v-slot:default="{ item }">
                <v-list-item :key="item.data().id" @click="call(item)">
                    <v-tooltip right color="black">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item-action v-bind="attrs" v-on="on" class="mr-3">
                                <v-icon small  :color="item.data().onlineStatus" >
                                    mdi-checkbox-blank-circle
                                </v-icon>
                            </v-list-item-action>
                        </template>
                        <span v-if="item.data().onlineStatus === 'green'" >Online (Click to Call)</span>
                        <span v-if="item.data().onlineStatus === 'red'" >Unavailable</span>
                        <span v-if="item.data().onlineStatus === 'yellow'" >Busy</span>
                    </v-tooltip>
                    <v-list-item-content>
                        <v-list-item-title>
                            <span>{{item.data().name}}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-progress-linear
                        v-if="item.id === calleeId"
                        indeterminate
                        stream
                        height="8"
                        reverse
                        rounded
                        background-opacity=0.2
                        color="primary">
                    <span class="text-caption" style="font-size: 8px !important;">Calling</span>
                </v-progress-linear>
                <v-divider></v-divider>
            </template>
        </v-virtual-scroll>
        <div v-if="filteredJudges.length === 0">
            <div class="d-flex flex-column align-center grey--text text--darken-1"
                 v-if="searchText !== ''">
                <span> Cannot find:</span>
                <p><strong>{{searchText}}</strong></p>
            </div>
            <div class="d-flex flex-column align-center grey--text text--darken-1"
                 v-if="searchText === ''">
                <span class="text-center">It looks like everyone is busy/unavailable.</span>
            </div>
            <v-icon large class="d-flex justify-center" color="primary">
                mdi-emoticon-sad-outline
            </v-icon>
        </div>

        <!--<v-list class=" v-list&#45;&#45;dense grow mt-2"  style="max-width: 230px">-->
            <!--<v-list-item v-for="(judge, i) in filteredJudges" :key="i" @click="call(judge)" >-->
                <!--<v-row class="no-gutters">-->
                    <!--<v-tooltip right color="black">-->
                        <!--<template v-slot:activator="{ on, attrs }">-->
                            <!--<v-col cols="2" align="left" class="" v-bind="attrs" v-on="on">-->
                                <!--<v-icon small  :color="judge.data().onlineStatus">mdi-checkbox-blank-circle</v-icon>-->
                            <!--</v-col>-->
                            <!--<v-col align="left" >-->
                                <!--<span>{{judge.data().name}}</span>-->
                            <!--</v-col>-->
                        <!--</template>-->
                        <!--<span v-if="judge.data().onlineStatus === 'green'" >Online (Click to Call)</span>-->
                        <!--<span v-if="judge.data().onlineStatus === 'red'" >Unavailable</span>-->
                        <!--<span v-if="judge.data().onlineStatus === 'yellow'" >Busy</span>-->
                    <!--</v-tooltip>-->
                    <!--<v-progress-linear-->
                            <!--v-if="judge.id === calleeId"-->
                            <!--indeterminate-->
                            <!--stream-->
                            <!--height="8"-->
                            <!--reverse-->
                            <!--rounded-->
                            <!--background-opacity=0.2-->
                            <!--color="primary">-->
                        <!--<span class="text-caption" style="font-size: 8px !important;">Calling</span>-->
                    <!--</v-progress-linear>-->
                <!--</v-row>-->
            <!--</v-list-item>-->

            <!--<div v-if="filteredJudges.length === 0">-->
                <!--<div class="d-flex flex-column align-center grey&#45;&#45;text text&#45;&#45;darken-1"-->
                      <!--v-if="searchText !== ''">-->
                    <!--<span> Cannot find:</span>-->
                    <!--<p><strong>{{searchText}}</strong></p>-->
                <!--</div>-->
                <!--<div class="d-flex flex-column align-center grey&#45;&#45;text text&#45;&#45;darken-1"-->
                     <!--v-if="searchText === ''">-->
                    <!--<span class="text-center">It looks like everyone is busy/unavailable.</span>-->
                <!--</div>-->
                <!--<v-icon large class="d-flex justify-center" color="primary">-->
                    <!--mdi-emoticon-sad-outline-->
                <!--</v-icon>-->
            <!--</div>-->
        <!--</v-list>-->
    </v-container>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'judgeNav',
        components:{

        },
        data(){
            return{
                searchText:'',
                ascending: true,
                radios: null,
                calleeId:null,
                calleeName:null,
                warning: false,
                windowHeight: window.innerHeight - 150,
            }
        },
        methods:{
            call(calleeObj){
                this.calleeId = calleeObj.id;
                this.calleeName = calleeObj.data().name;
                if(calleeObj.data().onlineStatus === 'green'){
                    //this.outCallDialog = true;
                    if(this.$router.currentRoute.path === '/'){
                        const callObj = {calleeObj: calleeObj, callType: 'new'};
                        this.$store.dispatch('handleOutgoingCall', callObj);
                    }
                    else{
                        const callObj = {calleeObj: calleeObj, callType: 'add'};
                        this.$store.dispatch('handleOutgoingCall', callObj);
                    }
                }
                else{
                    this.warning = true;
                    setTimeout(()=>{
                        this.warning = false;
                    }, 20000);
                }
            }
        },
    computed:{
    ...mapState(['allJudgesProfiles', 'callStatus']),
        filteredJudges(){
            let tempJudges = Array.from(this.allJudgesProfiles);

            if(this.searchText !== '' && this.searchText){
                tempJudges = tempJudges.filter((item) => {
                    return item.data().name
                        .toUpperCase()
                        .includes(this.searchText.toUpperCase())
                })
            }

            if(this.radios === 'green'){
                tempJudges = tempJudges.filter((item) => {
                    return item.data().onlineStatus
                        .toUpperCase()
                        .includes(this.radios.toUpperCase())
                });
            }

            tempJudges = tempJudges.sort((a,b) => {
                let fa = a.data().name.toLowerCase();
                let fb = b.data().name.toLowerCase();
                if (fa < fb) {
                    return -1
                }
                if (fa > fb) {
                    return 1
                }
                return 0
            });

            if(!this.ascending){
                tempJudges.reverse();
            }

            return tempJudges;
        }
    },
        watch:{
            callStatus: function (newVal) {
                console.log("JUDGE NAV: " + newVal.callstatus );
                if(newVal.callstatus !== 'initiated' && newVal.callstatus !== 'addNewUser'){
                    this.calleeId = null;
                    console.log("JUDGE NAV: CalleeID: " + this.calleeId);
                }
            }
        }

    }


</script>

<style>

</style>