import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import { auth } from './firebase'
import LoadScript from 'vue-plugin-load-script';
import * as firebase from "firebase";


Vue.config.productionTip = false;
Vue.use(LoadScript);

Vue.loadScript("https://claraconnect.robotaisolutions.com/external_api.js")
    .then(() => {

        console.log("Jitsi Meet Script Loaded");
    })
    .catch(() => {
        console.log("ERROR: Jitsi Meet Script NOT Loaded");
    });




let app;
auth.onAuthStateChanged((user) => {
    if(!app){
        new Vue({
            router,
            vuetify,
            store,
            render: h => h(App)
        }).$mount('#app')
    }
    if (user) {
        store.dispatch('fetchUserProfile', user);
        //Realtime user presence test

        var uid = firebase.auth().currentUser.uid;

        var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

        var isOfflineForDatabase = {
            state: 'red',
            last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

        var isOnlineForDatabase = {
            state: 'green',
            last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

        firebase.database().ref('.info/connected').on('value', function(snapshot) {
            // If we're not currently connected, don't do anything.
            if (snapshot.val() === false) {
                return;
            }

            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
                userStatusDatabaseRef.set(isOnlineForDatabase);
            });
        });
    }
})

